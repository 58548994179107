<template>
  <div>
    <h1 class="mt-4 mb-4">Estadísticas</h1>

    <p v-show="errorStats">Error obteniendo las estadísticas</p>

    <h3 v-show="loadingStats" class="text-center"><i class="fas fa-circle-notch fa-spin"></i></h3>

    <div v-if="!errorStats && !loadingStats" class="row">
      <div class="col-sm-auto border m-3 shadow bg-light">
        <div class="card-body text-center">
          <h5><i class="fas fa-wallet"></i> Balance</h5>
          <hr>
          <div>
            <p>{{ stats.ingresos - stats.gastos }} €</p>
          </div>
        </div>
      </div>

      <div class="col-sm-auto border m-3 shadow bg-light">
        <div class="card-body text-center">
          <h5><i class="fas fa-user"></i> Usuarios</h5>
          <hr>
          <div class="text-left">
            <p>Total DB: {{ stats.totalUsuariosDb }}</p>
            <p>Total Emby: {{ stats.totalUsuariosEmby }}</p>
            <p>Activados: {{ stats.totalUsuariosActivados }}</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import config from '../config'

  export default {
    data() {
      return {
        loadingStats: true,
        errorStats: false,
        stats: {
          ingresos: null,
          gastos: null,
          totalUsuariosDb: null,
          totalUsuariosEmby: null,
          totalUsuariosActivados: null
        }
      }
    },
    methods: {
      async getEstadisticas() {
        try {
          this.loadingStats = true
          const res = await axios.get(config.apiUrl + '/estadisticas')
          this.loadingStats = false

          this.stats = res.data
        } catch (e) {
          this.loadingStats = false
          this.errorStats = true
        }
      }
    },
    mounted() {
      this.$store.commit('setPanelLayout')
      this.getEstadisticas()
    }
  }
</script>
